* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 75px;
    background-color: #ffffff;
    color: #616161;
}

.nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 75%;
}

.home,
.support,
.privacy,
.termsOfService {
    text-decoration: none;
    color: #616161;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    padding: 10px 20px;
    margin: 0px 10px;
    cursor: pointer;
}

.mobile-menu-icon {
    display: none;

}

@media screen and (max-width: 890px) {
    .log {
        display: flex;
        position: absolute;
        top: 15px;
        left: 35px;
    }

    .nav-links {
        display: none;
    }

    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        background-color: #ffffff;
        left: 0;
        top: 75px;
        transition: all 0.3s ease-in-out;
        width: 100%;

    }

    .home,
    .support,
    .privacy,
    .termsOfService {
        color: #616161;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.3s ease-in-out;

    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        color: #616161;
        background-color: #ffffff;
        border: none;
        outline: none;
        top: 15px;
        right: 25px;
    }
}