.page-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .page-title {
    text-decoration: none;
    color: #333;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .page-body-text {
    text-decoration: none;
    color: #616161;
    font-family: 'Poppins', sans-serif;
    font-weight: none;
    transition: color 0.3s ease;
    width: 50%;
  }
  
  /*HOME PAGE SPECIFIC*/
  .apple-button {
    color: #007aff; /* Link color */
    cursor: pointer; /* Show pointer cursor on hover */
    padding: 500;
  }
  
  .apple-button:hover {
    color: #0056b3; /* Link color on hover */
  }
