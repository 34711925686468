.footer {
  /* Your footer styles */
  background-color: #428DFB;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-text {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  color: #ffffff;
}